import React from 'react';
import { graphql } from 'gatsby';
import tw, { css } from 'twin.macro';
import Action from '../atoms/action';
import Sandbox from '../atoms/sandbox';
import Breadcrumb from '../atoms/breadcrumb';

const AltText = ({ data, theme }) => (
  <div
    css={[
      tw`relative pt-6 px-6 pb-12 text-center bg-brand-purple-200`,
      tw`sm:(px-20 pt-12 pb-20)`,
      tw`lg:(px-28 pt-20 pb-28)`,
      tw`xl:(px-48 pt-24 pb-36)`,
      css`
        ol {
          list-style-type: none;
          counter-reset: app-sandbox-counter;
          ${tw`mt-4 2xl:mt-8`}
          li {
            counter-increment: app-sandbox-counter;
            ${tw`mb-4 xl:mb-6`}
            &:before {
              content: counter(app-sandbox-counter);
              ${tw`flex items-center justify-center font-bold mx-auto mb-1 text-brand-purple-800 border border-brand-purple-800 rounded-full w-8 h-8 text-sm`}
              ${tw`2xl:(w-10 h-10 text-base mb-4)`}
            }
          }
        }
      `,
    ]}
  >
    <div tw="max-w-screen-lg mx-auto">
      {data.breadcrumb && <Breadcrumb text={data.breadcrumb} theme={theme} />}
      <h3 tw="text-3xl leading-none font-black text-brand-green mb-6 lg:text-4xl">
        {data.title}
      </h3>
      <Sandbox
        html={data.body}
        tw="max-w-prose mx-auto xl:(text-xl leading-relaxed) 2xl:(text-2xl leading-relaxed)"
      />
      <div tw="flex items-center justify-center mt-8 text-sm sm:text-base 2xl:(mt-16 text-xl)">
        {data.actions.map((action) => (
          <Action data={action} key={action.id} />
        ))}
      </div>
    </div>
  </div>
);

export const query = graphql`
  fragment AltText on STRAPI_ComponentContainersAltText {
    id
    title
    body
    breadcrumb
    actions {
      ...Action
    }
  }
`;

export default AltText;
